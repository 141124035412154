import React, { useState } from "react";
import logo from "../assets/krabbi-logo.png";
import { Link } from "react-router-dom";
import { NavHashLink } from "react-router-hash-link/dist/react-router-hash-link.cjs.production";
import { useTranslation } from "react-i18next";
import Flag from "react-world-flags";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import i18next from "i18next";
import Cookies from "js-cookie";

function Nav({ languages, admin }) {
  const { t } = useTranslation();

  const [dropdownOpen, setDropdownOpen] = useState(false);

  function toggleDropdown() {
    setDropdownOpen(!dropdownOpen);
  }
  const currentLanguageCode = Cookies.get("i18next") || "en";

  return (
    <nav className="navbar">
      <ul className="nav__list">
        <li>
          <NavHashLink to={"/#menu"} className="nav__list--anchor">
            {t("nav_menu")}
          </NavHashLink>
        </li>
        <li className="nav__logo">
          <Link to={"/"}>
            <img src={logo} alt="" />
          </Link>
        </li>
        <li>
          <Link to={"/gallery"} className="nav__list--anchor">
            {" "}
            {t("nav_gallery")}
          </Link>
        </li>
       
      </ul>
      <div className="dropdown">
        <button className="dropdown__btn" onClick={toggleDropdown}>
          <FontAwesomeIcon icon={faGlobe} />
        </button>
        <ul
          className="dropdown__menu"
          style={{ display: !dropdownOpen ? "none" : "block" }}
        >
          {languages.map(({ code, name, country_code }) => (
            <li key={code} className="dropdown__list">
              <button
                className="dropdown__item"
                onClick={() => i18next.changeLanguage(code) && toggleDropdown()}
                disabled={code === currentLanguageCode}
              >
                <Flag
                  className="dropdown__flag"
                  code={country_code}
                  style={{ opacity: code === currentLanguageCode ? 0.5 : 1 }}
                />
                {name}{" "}
              </button>
            </li>
          ))}
        </ul>
      </div>
    </nav>
  );
}

export default Nav;
