import React from "react";
import { Link } from "react-router-dom";
import Logo from "../assets/krabbi-logo.png";

function AdminNav({ setActiveComponent }) {
  return (
    <div className="navbar">
        <Link to={"/"} className="admin__logo">
          <img src={Logo} alt="" />
        </Link>
      <ul className="nav__list">
        <li>
          <button
            className="nav__btn"
            onClick={() => setActiveComponent("products")}
          >
            Products
          </button>
        </li>
        <li>
          <button
            className="nav__btn"
            onClick={() => setActiveComponent("events")}
          >
            Events
          </button>
        </li>
      </ul>
    </div>
  );
}

export default AdminNav;
