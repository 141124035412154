import React, { useEffect, useState } from "react";
import { db } from "../firebase";
import { collection, getDocs } from "firebase/firestore";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";

function Events() {
  const { t } = useTranslation();

  const [events, setEvents] = useState([]);
  useEffect(() => {
    async function getEvents() {
      const { docs } = await getDocs(collection(db, "events"));
      const eventData = docs.map((elem) => ({ ...elem.data(), id: elem.id }));
      const futureEvents = eventData.filter((event) => {
        const eventDate = new Date(event.date.toMillis());
        const currentDate = new Date();
        return eventDate > currentDate;
      });
      setEvents(futureEvents);
    }
    getEvents();
  }, []);
  return (
    <section id="events">
      <div className="container">
        <div className="row">
          <h1 className="header">{t("event_header")}</h1>
          <div className="events__wrapper">
            {events.length > 0 ? (
              events.slice(0, 3).map((event) => (
                <div className="event" key={event.id}>
                  <a href={event.link} target="_blank">
                    <img src={event.img} alt="" />
                  </a>
                  <p className="event__time">
                    {new Date(event.date.toMillis()).toLocaleString("en-GB", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                      hour: "numeric",
                      minute: "numeric",
                      hour12: false,
                    })}
                  </p>
                </div>
              ))
            ) : (
              <div className="no__events">
                <h1>{t("no_event_header")}</h1>
                <a
                  href="https://www.facebook.com/profile.php?id=61552245099311"
                  target="_blank"
                >
                  <FontAwesomeIcon className="icon" icon={faFacebook} />
                </a>
                <p>{t("no_event_para")}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Events;
