import React, { useEffect, useRef, useState } from "react";
import emailjs from "emailjs-com";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: "90%",
    width: "75%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
  },
};

Modal.setAppElement("#root");

function OrderModal({ isOpen, closeModal, cartItems, setCartItems, total }) {
  const form = useRef();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [message, setMessage] = useState("");
  const [emailSent, setEmailSent] = useState(false);
  const [loading, setLoading] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    setLoading(true);

    const userId = "RgiV5q1Ri8uMCOyb8"; // Replace with your EmailJS user ID
    const serviceId = "service_xdrw4ld";
    const templateId = "template_uu10w9f";

    const emailParams = {
      to_email: `"recipient@example.com"`, // Replace with the recipient's email address
      subject: "Order Details",
      order_items: cartItems
        .map(
          (item) =>
            `${item.name} (${item.quantity}x): ${
              item.price * item.quantity
            } eur`
        )
        .join("\n"),
      from_name: name,
      from_email: email,
      from_number: number,
      from_message: message,
    };

    emailjs
      .send(serviceId, templateId, emailParams, userId)
      .then(() => {
        setLoading(false);
        setEmailSent(true);
        setCartItems([]);
      })
      .catch((error) => {
        console.error(error.text);
      });
  };
  useEffect(() => {
    if (!isOpen) {
      setEmailSent(false);
    }
  }, [isOpen]);
  return (
    <div>
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        overlayClassName={"modal__overlay"}
      >
        <span onClick={() => closeModal()} className="close__modal">
          X
        </span>
        <div className="checkout__wrapper">
          {emailSent ? (
            <div className="success">
              <FontAwesomeIcon className="check" icon={faCircleCheck} />
              <p>
                Your Order has been sent, we will get in touch with you as soon
                as we can
              </p>
            </div>
          ) : (
            <div>
              <p className="form__text">
                Please add your contact information, so that we can notify you
                about your order{" "}
              </p>
              <form ref={form} onSubmit={sendEmail} className="checkout__form">
                <div className="checkout__details">
                  <label className="checkout__label" htmlFor="name">
                    Name
                  </label>
                  <input
                    className="checkout__input"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    type="text"
                    required
                  />
                  <label className="checkout__label" htmlFor="email">
                    Email
                  </label>
                  <input
                    className="checkout__input"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                    required
                  />
                  <label className="checkout__label" htmlFor="number">
                    Phone number
                  </label>
                  <input
                    className="checkout__input"
                    value={number}
                    onChange={(e) => setNumber(e.target.value)}
                    type="tel"
                    required
                  />
                  <label className="checkout__label" htmlFor="message">
                    Message
                  </label>
                  <textarea
                    className="checkout__input message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder="Add an extra message if you have any questions"
                  ></textarea>
                  <div className="checkout__radio">
                    <input type="checkbox" name="permission" required />
                    <label htmlFor="agree">
                      I consent to the use of these provided details for the
                      purpose of contact, and I grant permission for such usage
                    </label>
                  </div>
                  {loading ? (
                    <div className="spinner"></div>
                  ) : (
                    <button className="checkout__btn" type="submit">
                      Place Order
                    </button>
                  )}
                </div>
              </form>
            </div>
          )}
          <div className="checkout__order-details">
            <div>
              <h2>Your order</h2>
              {cartItems.map((item) => (
                <div key={item.id}>
                  <p className="cart__list">
                    {item.name} <span> {item.quantity}x</span>
                  </p>
                </div>
              ))}
            </div>
            <div>
              <h2 style={{ display: "flex", justifyContent: "space-between" }}>
                Total: <span> {total()} eur</span>
              </h2>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default OrderModal;
