import React from "react";
import { Gallery, Item } from "react-photoswipe-gallery";
import "photoswipe/dist/photoswipe.css";
import { useTranslation } from "react-i18next";

function Menu() {
  const { t } = useTranslation();

  return (
    <section id="menu">
      <div className="container">
        <div className="row">
          <h1 className="header">{t("menu_header")}</h1>
          <div className="menu__wrapper">
            <div className="menu">
              <Gallery>
                <Item
                  original={t("menu_img1")}
                  thumbnail={t("menu_img1")}
                  width="600"
                  height="800"
                >
                  {({ ref, open }) => (
                    <img
                      ref={ref}
                      onClick={open}
                      src={t("menu_img1")}
                      alt=""
                      style={{ cursor: "pointer" }}
                    />
                  )}
                </Item>
                <Item
                  original={t("menu_img2")}
                  thumbnail={t("menu_img2")}
                  width="600"
                  height="800"
                >
                  {({ ref, open }) => (
                    <img
                      ref={ref}
                      onClick={open}
                      src={t("menu_img2")}
                      alt=""
                      style={{ cursor: "pointer" }}
                    />
                  )}
                </Item>
                <Item
                  original={t("menu_img3")}
                  thumbnail={t("menu_img3")}
                  width="600"
                  height="800"
                >
                  {({ ref, open }) => (
                    <img
                      ref={ref}
                      onClick={open}
                      src={t("menu_img3")}
                      alt=""
                      style={{ cursor: "pointer" }}
                    />
                  )}
                </Item>
              </Gallery>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Menu;
