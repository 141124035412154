import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function Footer() {
  const { t } = useTranslation();
  function ScrollToTop() {
    window.scrollTo(0, 0);
  }

  return (
    <footer id="footer">
      <div className="container">
        <div className="row">
          <div className="footer__wrapper">
            <div className="footer__connect">
              <div className="footer__contact">
                <h1 className="footer__header">{t("footer_contact")}</h1>
                <a href="tel:+34611821499">
                  <p>+34 611 82 14 99</p>
                </a>
                <a href="mailto:Andrus.krabbi@gmail.com">
                  <p>Andrus.krabbi@gmail.com</p>
                </a>
              </div>
              <div className="footer__socials">
                <h1 className="footer__header">{t("footer_socials")}</h1>
                <a
                  href="https://www.facebook.com/profile.php?id=61552245099311"
                  target="_blank"
                >
                  <p>Facebook</p>
                </a>
                <a
                  href="https://www.instagram.com/krabbi_beach_bar/"
                  target="_blank"
                >
                  <p>Instagram</p>
                </a>
              </div>
            </div>
            <div className="footer__location">
              <h1 className="footer__header">{t("footer_location")}</h1>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3202.4469525099116!2d-4.502773888300903!3d36.61562677796617!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd72fb0821aa38f5%3A0x8fad2e04d0f88d10!2sKrabbi%20Beach%20Bar!5e0!3m2!1sen!2see!4v1698064655022!5m2!1sen!2see"
                width="700"
                height="300"
                style={{ border: 0, width: "100%" }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                title="map"
              ></iframe>
              <Link to={"/admin"} onClick={ScrollToTop}>
                admin
              </Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
