import Footer from "./components/Footer";
import Nav from "./components/Nav";
import FrontPage from "./pages/FrontPage";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Gallery from "./pages/Gallery";
import BlogPage from "./pages/BlogPage";
import Shop from "./pages/Shop";
import { useEffect, useState } from "react";
import Admin from "./pages/Admin";
import Login from "./pages/Login";

const languages = [
  {
    code: "en",
    name: "English",
    country_code: "gb",
  },
  {
    code: "est",
    name: "Eesti",
    country_code: "est",
  },
  {
    code: "esp",
    name: "español",
    country_code: "esp",
  },
];

function App() {
  const [cartItems, setCartItems] = useState([]);

  function addToCart(product) {
    setCartItems([...cartItems, { ...product, quantity: 1 }]);
  }
  function removeItem(itemToRemove) {
    const updatedCartItems = [...cartItems];
    const indexToRemove = updatedCartItems.findIndex(
      (item) => item.id === itemToRemove.id
    );

    if (indexToRemove !== -1) {
      updatedCartItems.splice(indexToRemove, 1);
      setCartItems(updatedCartItems);
    }
  }
  function changeQuantity(product, quantity) {
    setCartItems(
      cartItems.map((item) => {
        if (item.id === product.id) {
          return {
            ...item,
            quantity: +quantity,
          };
        } else {
          return item;
        }
      })
    );
  }
  const [admin, setAdmin] = useState(null);

  useEffect(() => {}, [cartItems]);
  return (
    <div className="App">
      <Router>
        <Nav languages={languages} admin={admin} />
        <Routes>
          <Route exact path="/" element={<FrontPage />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/blog/:id" element={<BlogPage />} />

          <Route path="/admin" element={<Admin admin={admin} />} />
          <Route path="/admin/login" element={<Login setAdmin={setAdmin} />} />
          <Route path="*" element={<Navigate to={"/"} />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
/*
<Route
  path="/shop"
  element={
    <Shop
      cartItems={cartItems}
      setCartItems={setCartItems}
      addToCart={addToCart}
      removeItem={removeItem}
      changeQuantity={changeQuantity}
    />
  }
/>*/