import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { db } from "../firebase";
import { doc, getDoc } from "firebase/firestore";

function BlogPage() {
  const { id } = useParams();
  const [blogData, setBlogData] = useState([]);
  const [loading, setLoading] = useState(true);

  async function getBlogData() {
    const docRef = doc(db, "blogs-eng", `${id}`);
    const docSnap = await getDoc(docRef);
    const data = docSnap.data();
    setBlogData(data);
    setLoading(false)
  }
  console.log(blogData);
  useEffect(() => {
    getBlogData();
  }, []);

  return (
    <section id="blog" className="blogpage">
      <div className="container">
        <div className="row">
          {loading ? (
           <div className="blogpage__wrapper--skeleton">
           <div className="blogpage__extra--skeleton">
             <div className="skeleton skeleton__header"></div>
             <div className="skeleton skeleton__para"></div>
             <div className="skeleton skeleton__para"></div>
             <div className="skeleton skeleton__para"></div>
             <div className="skeleton skeleton__para"></div>
             <div className="skeleton skeleton__para"></div>
           </div>
         </div> 
          ) : (
            <div className="blogpage__wrapper">
              <div className="blogpage__extra">
                <h2>{blogData.title}</h2>
                <p>{blogData.para}</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
}

export default BlogPage;
