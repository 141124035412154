import React, { useEffect, useState } from "react";
import AdminNav from "../components/AdminNav";
import AdminProducts from "../components/AdminProducts";
import AdminEvents from "../components/AdminEvents";
import AdminBlog from "../components/AdminBlog";
import { useNavigate } from "react-router-dom";

function Admin({ admin }) {
  const [activeComponent, setActiveComponent] = useState("");
  const navigate = useNavigate();

  function renderComponent() {
    switch (activeComponent) {
      case "products":
        return <AdminProducts />;
      case "events":
        return (
          <AdminEvents resetActiveComponent={() => setActiveComponent("")} />
        );
      case "blog":
        return <AdminBlog />;
      default:
        return null;
    }
  }
  function checkAdmin(admin) {
    if (!admin){
      navigate('/admin/login')
    }
  }
  useEffect(() => {
    checkAdmin(admin)
    document.body.classList.add("admin");

    return () => {
      document.body.classList.remove("admin");
    };
  }, [admin]);

  return (
    <>
      <AdminNav setActiveComponent={setActiveComponent}  />
      <div id="admin">{renderComponent()}</div>
    </>
  );
}

export default Admin;
