import React from "react";
import Hero from "../components/Hero";
import Menu from "../components/Menu";
import Events from "../components/Events";
import Blog from "../components/Blog";

function FrontPage() {
  return (
    <>
      <Hero />
      <Menu />
      <Events />
      <Blog />
    </>
  );
}

export default FrontPage;
