import React, { useEffect, useState } from "react";
import { db } from "../firebase";
import { collection, getDocs } from "firebase/firestore";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";

function Blog() {
  const { t } = useTranslation();

  const [blogs, setBlogs] = useState([]);
  async function getBlogs() {
    const { docs } = await getDocs(collection(db, "blogs-eng"));
    const blogsData = docs.map((elem, index) => ({
      ...elem.data(),
      id: elem.id,
      timestamp: index,
    }));
    blogsData.sort((a, b) => b.timestamp - a.timestamp);
    setBlogs(blogsData);
  }
  useEffect(() => {
    getBlogs();
  }, []);
  return (
    <section id="blog">
      <div className="container">
        <div className="row">
          <h1 className="header">{t("blog_header")}</h1>
          <div className="blog__wrapper">
            {blogs.length > 0 ? (
              blogs.slice(0, 3).map((blog) => (
                <div className="blog" key={blog.id}>
                  <h2 className="blog__title">{blog.title}</h2>
                  <p className="blog__description">
                    {blog.para.slice(0, 200)}...
                  </p>
                  <Link className="blog__link" to={`/blog/${blog.id}`}>
                    Click here to learn more
                  </Link>
                </div>
              ))
            ) : (
              <div className="no__events">
                <h1>{t("no_blog_header")}</h1>
                <a
                  href="https://www.facebook.com/profile.php?id=61552245099311"
                  target="_blank"
                >
                  <FontAwesomeIcon className="icon" icon={faFacebook} />
                </a>
                <p>{t("no_blog_para")}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Blog;
