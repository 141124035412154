import { addDoc, collection, doc, updateDoc } from "firebase/firestore";
import React, { useState } from "react";
import Modal from "react-modal";
import { db, storage } from "../firebase";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: "80%",
    width: "80%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
};
Modal.setAppElement("#root");

function AddProductModal({ isModalOpen, openModal, closeModal }) {
  const [name, setName] = useState("");
  const [price, setPrice] = useState(0);
  const [weight, setWeight] = useState(0);
  const [description, setDescription] = useState("");
  const [image, setImage] = useState(null);
  const [imageURL, setImageURL] = useState("");
  const [loading, setLoading] = useState(false);

  function uploadImage(e) {
    const file = e.target.files[0];
    setImage(file);

    const reader = new FileReader();
    reader.onload = (event) => {
      setImageURL(event.target.result);
    };
    reader.readAsDataURL(file);
  }

  async function addProduct() {
    const product = {
      name: name,
      price: Number(price),
      weight: Number(weight),
      description: description,
    };
    const productRef = await addDoc(collection(db, "products"), product);
    const productId = productRef.id;
    setLoading(true);

    if (image) {
      const storageRef = ref(storage, `products/${productId}/${image.name}`);
      const uploadTask = uploadBytesResumable(storageRef, image);

      await uploadTask;

      const imageUrl = await getDownloadURL(storageRef);

      await updateDoc(doc(db, "products", productId), {
        img: imageUrl,
      });
    }
    setImage(null);
    setLoading(false);
    closeModal();
  }
  return (
    <Modal
      isOpen={isModalOpen}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <span onClick={() => closeModal()} className="close__modal">
        X
      </span>

      <h2>Add a New Product</h2>
      <form className="product__form">
        <div className="product__input">
          <label>Name:</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="product__input">
          <label>Price:</label>
          <input
            type="number"
            value={price}
            onChange={(e) => setPrice(Number(e.target.value))}
          />
        </div>
        <div className="product__input">
          <label>Weight:</label>
          <input
            type="number"
            value={weight}
            onChange={(e) => setWeight(Number(e.target.value))}
          />
        </div>
        <div className="product__input">
          <label>Description</label>
          <textarea
            type="text"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            style={{ height: "150px" }}
          />
        </div>

        <label>Image:</label>
        <input type="file" onChange={uploadImage} />
        {imageURL && (
          <img
            src={imageURL}
            alt="Preview"
            style={{ width: "100px", height: "100px" }}
          />
        )}
        {loading ? (
          <div className="spinner"></div>
        ) : (
          <button type="button" className="admin__btn btn" onClick={addProduct}>
            Add Product
          </button>
        )}
      </form>
    </Modal>
  );
}

export default AddProductModal;
