import React from "react";
import img1 from "../krabbiimg/img1.jpg";
import img2 from "../krabbiimg/img2.jpg";
import img3 from "../krabbiimg/img3.jpg";
import img4 from "../krabbiimg/img4.jpg";
import img5 from "../krabbiimg/img5.jpg";
import img6 from "../krabbiimg/img6.jpg";
import img8 from "../krabbiimg/img8.jpg";
import img9 from "../krabbiimg/img9.jpg";
import img10 from "../krabbiimg/img10.jpg";
import img11 from "../krabbiimg/img11.jpg";
import img12 from "../krabbiimg/img12.jpg";
import { Gallery as GalleryFunc, Item } from "react-photoswipe-gallery";
import "photoswipe/dist/photoswipe.css";
import { useTranslation } from "react-i18next";

function Gallery() {
  const { t } = useTranslation();

  return (
    <section id="gallery">
      <div className="container">
        <div className="row gallery__row">
          <h1 className="header">{t("nav_gallery")}</h1>
          <div className="gallery__wrapper">
            <GalleryFunc>
              <Item original={img1} thumbnail={img1} width="750" height="500">
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={img1}
                    alt=""
                    style={{
                      cursor: "pointer",
                    }}
                    loading="lazy"
                  />
                )}
              </Item>
              <Item original={img2} thumbnail={img2} width="750" height="500">
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={img2}
                    alt=""
                    style={{ cursor: "pointer" }}
                    loading="lazy"
                  />
                )}
              </Item>
              <Item original={img3} thumbnail={img3} width="750" height="500">
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={img3}
                    alt=""
                    style={{ cursor: "pointer" }}
                    loading="lazy"
                  />
                )}
              </Item>
              <Item original={img4} thumbnail={img4} width="750" height="500">
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={img4}
                    alt=""
                    style={{ cursor: "pointer" }}
                    loading="lazy"
                  />
                )}
              </Item>
              <Item original={img5} thumbnail={img5} width="750" height="500">
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={img5}
                    alt=""
                    style={{ cursor: "pointer" }}
                    loading="lazy"
                  />
                )}
              </Item>
              <Item original={img6} thumbnail={img6} width="750" height="500">
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={img6}
                    alt=""
                    style={{ cursor: "pointer" }}
                    loading="lazy"
                  />
                )}
              </Item>
              <Item original={img8} thumbnail={img8} width="750" height="500">
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={img8}
                    alt=""
                    style={{ cursor: "pointer" }}
                    loading="lazy"
                  />
                )}
              </Item>
              <Item original={img9} thumbnail={img9} width="750" height="500">
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={img9}
                    alt=""
                    style={{ cursor: "pointer" }}
                    loading="lazy"
                  />
                )}
              </Item>
              <Item original={img10} thumbnail={img10} width="750" height="500">
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={img10}
                    alt=""
                    style={{ cursor: "pointer" }}
                    loading="lazy"
                  />
                )}
              </Item>
              <Item original={img11} thumbnail={img11} width="750" height="500">
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={img11}
                    alt=""
                    style={{ cursor: "pointer" }}
                    loading="lazy"
                  />
                )}
              </Item>
              <Item original={img12} thumbnail={img12} width="750" height="500">
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={img12}
                    alt=""
                    style={{ cursor: "pointer" }}
                    loading="lazy"
                  />
                )}
              </Item>
            </GalleryFunc>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Gallery;
