import React from "react";
import dinner from "../assets//img1.jpg";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function Hero() {
  const { t } = useTranslation();

  return (
    <section id="hero">
      <div className="container">
        <div className="row">
          <div className="hero__wrapper">
            <div className="hero__text">
              <h1 className="hero__header">{t("welcome_message")}</h1>
              <h2 className="hero__subheader">{t("hero_subheader")}</h2>
              <div className="timetables">
                <div className="timetable">
                  <span className="day">{t("open_times")}</span>
                  <span className="hours">10:00 - 22:00</span>
                </div>
                <div className="timetable">
                  <span className="day">
                    C. los Perros, 12, 29620 Torremolinos, Málaga, Spain
                  </span>
                </div>
                <div className="timetable">
                  <span className="day">+34 611 82 14 99</span>
                </div>
              </div>
            </div>
            <div className="hero__img">
              <img src={dinner} alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Hero;

/*<div className="shop-link" style={{ paddingLeft: "6px" }}>
                  <Link to={"/shop"}>{t("shop_link")} </Link>
                </div>*/
