import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAnHw4mAQ4AjpDa223ZCnMn0h9dDmMlW8k",
  authDomain: "krabbi-beach-bar.firebaseapp.com",
  projectId: "krabbi-beach-bar",
  storageBucket: "krabbi-beach-bar.appspot.com",
  messagingSenderId: "111793844750",
  appId: "1:111793844750:web:b258214ec3a2bdcb7f425f",
};

const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
export const storage = getStorage(app);
export const auth = getAuth(app);
