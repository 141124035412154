import React, { useEffect, useState } from "react";
import "../login.css";
import { useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebase";
function Login({ setAdmin }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const authToken = sessionStorage.getItem("authToken");
    if (authToken) {
      setAdmin(authToken);
      navigate("/admin");
    }
  }, []);

  function signIn(e) {
    e.preventDefault();

    signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        sessionStorage.setItem("authToken", email);
        setAdmin(email);
        navigate("/admin");
      })
      .catch(() => {
        setErrorMessage("wrong email or password");
      });
  }
  return (
    <section id="login">
      <form className="form" onSubmit={signIn}>
        <p className="form-title">Sign in to your account</p>
        {errorMessage}
        <div className="input-container">
          <input
            type="email"
            placeholder="Enter email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="input-container">
          <input
            type="password"
            placeholder="Enter password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="submit">
          Sign in
        </button>
      </form>
    </section>
  );
}

export default Login;
