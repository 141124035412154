import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  onSnapshot,
  updateDoc,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { db } from "../firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "../firebase";
import AddProductModal from "../components/AddProductModal";

const customStyles = {
  rows: {
    style: {
      minHeight: "72px", // override the row height
    },
  },
  headCells: {
    style: {
      paddingLeft: "8px", // override the cell padding for head cells
      paddingRight: "8px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  cells: {
    style: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
};

const columns = [
  {
    name: "name",
    selector: (row) => row.name,
    cell: (row) => (
      <EditableCell
        value={row.name}
        id={row.id}
        field="name"
        updateData={(id, field, value) => updateData(id, field, value)}
      />
    ),
  },
  {
    name: "price",
    selector: (row) => row.price,
    cell: (row) => (
      <EditablePriceCell
        value={row.price}
        type="number"
        id={row.id}
        field="price"
        updateData={(id, field, value) => updateData(id, field, value)}
      />
    ),
  },
  {
    name: "weight",
    selector: (row) => row.weight,
    cell: (row) => (
      <EditableWeightCell
        value={row.weight}
        id={row.id}
        field="weight"
        updateData={(id, field, value) => updateData(id, field, value)}
      />
    ),
  },
  {
    name: "image",
    selector: (row) => row.img,
    cell: (row) => (
      <EditableImageCell
        value={row.img}
        id={row.id}
        field="img"
        updateData={(id, field, value) => updateData(id, field, value)}
      />
    ),
  },
  {
    name: "Description",
    selector: (row) => row.description,
    cell: (row) => (
      <EditableDescriptionCell
        value={row.description}
        id={row.id}
        field="description"
        updateData={(id, field, value) => updateData(id, field, value)}
      />
    ),
  },

  {
    name: "actions",
    cell: (row) => (
      <button onClick={() => deleteProduct(row.id)} className="btn delete">
        Delete
      </button>
    ),
  },
];
function EditablePriceCell({ value, id, updateData }) {
  const [isEditing, setIsEditing] = useState(false);
  const [editedValue, setEditedValue] = useState(value);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = () => {
    updateData(id, "price", parseFloat(editedValue)); // Convert to number
    setIsEditing(false);
  };

  const handleCancel = () => {
    setEditedValue(value);
    setIsEditing(false);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSave();
    }
  };

  return isEditing ? (
    <div className="edit">
      <input
        type="number"
        value={editedValue}
        onChange={(e) => setEditedValue(e.target.value)}
        onBlur={handleSave} // Save on blur
        onKeyDown={handleKeyPress}
        className="edit__input"
      />
      <button onClick={handleCancel}>Cancel</button>
    </div>
  ) : (
    <button onClick={handleEdit} className="data__btn">
      {value} eur
    </button>
  );
}
function EditableCell({ value, id, field, updateData, type }) {
  const [isEditing, setIsEditing] = useState(false);
  const [editedValue, setEditedValue] = useState(value);

  const handleEdit = () => {
    setIsEditing(true);
  };
  const handleCancel = () => {
    setEditedValue(value);
    setIsEditing(false);
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      updateData(id, field, editedValue);
      setIsEditing(false);
    }
  };

  return isEditing ? (
    <div className="edit">
      <input
        value={editedValue}
        onChange={(e) => setEditedValue(e.target.value)}
        onKeyDown={handleKeyPress}
        className="edit__input long"
      />
      <button onClick={handleCancel}>Cancel</button>
    </div>
  ) : (
    <button onClick={handleEdit} className="data__btn">
      {value}
    </button>
  );
}
function EditableDescriptionCell({ value, id, field, updateData, type }) {
  const [isEditing, setIsEditing] = useState(false);
  const [editedValue, setEditedValue] = useState(value);

  const handleEdit = () => {
    setIsEditing(true);
  };
  const handleCancel = () => {
    setEditedValue(value);
    setIsEditing(false);
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      updateData(id, field, editedValue);
      setIsEditing(false);
    }
  };

  return isEditing ? (
    <div className="edit">
      <textarea
        value={editedValue}
        onChange={(e) => setEditedValue(e.target.value)}
        onKeyDown={handleKeyPress}
        className="edit__input long"
        style={{width:"100%", height:"100px"}}
      />
      <button onClick={handleCancel}>Cancel</button>
    </div>
  ) : (
    <button onClick={handleEdit} className="data__btn" style={{fontSize:"16px"}}>
      {value}
    </button>
  );
}
function EditableWeightCell({ value, id, updateData }) {
  const [isEditing, setIsEditing] = useState(false);
  const [editedValue, setEditedValue] = useState(value);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = () => {
    updateData(id, "weight", parseFloat(editedValue)); // Convert to number
    setIsEditing(false);
  };

  const handleCancel = () => {
    setEditedValue(value);
    setIsEditing(false);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSave();
    }
  };

  return isEditing ? (
    <div className="edit">
      <input
        type="number"
        value={editedValue}
        onChange={(e) => setEditedValue(e.target.value)}
        onBlur={handleSave} // Save on blur
        onKeyDown={handleKeyPress}
        className="edit__input"
      />
      <button onClick={handleCancel}>Cancel</button>
    </div>
  ) : (
    <button onClick={handleEdit} className="data__btn">
      {value} g
    </button>
  );
}

function EditableImageCell({ id, value, updateData }) {
  const [isEditing, setIsEditing] = useState(false);
  const [newImage, setNewImage] = useState(null);

  const handleImageClick = () => {
    setIsEditing(true);
  };
  const handleCancel = () => {
    setIsEditing(false);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setNewImage(file);

    handleSave(file);
  };

  const handleSave = async (file) => {
    if (newImage) {
      const storageRef = ref(storage, `products/${id}/${newImage.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on("state_changed", null, null, async () => {
        const newImageUrl = await getDownloadURL(storageRef);

        await updateDoc(doc(db, "products", id), {
          img: newImageUrl,
        });
        updateData(id, "img", newImageUrl);
        setIsEditing(false);
      });
    }
  };

  return (
    <div>
      {isEditing ? (
        <div className="edit">
          <input type="file" onChange={handleImageChange} />
          <button onClick={handleCancel}>Cancel</button>
        </div>
      ) : (
        <div onClick={handleImageClick}>
          <img
            src={value}
            alt="Product"
            style={{ width: "100px", height: "100px", cursor: "pointer" }}
          />
        </div>
      )}
    </div>
  );
}
async function updateData(id, field, value) {
  const docRef = doc(db, "products", id);
  await updateDoc(docRef, {
    [field]: value,
  });
}

function deleteProduct(productId) {
  const productRef = doc(db, "products", productId);
  deleteDoc(productRef);
}
function AdminProducts() {
  const [products, setProducts] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  function openModal() {
    setIsModalOpen(true);
  }
  function closeModal() {
    setIsModalOpen(false);
  }
  useEffect(() => {
    async function getProducts() {
      const { docs } = await getDocs(collection(db, "products"));
      const eventData = docs.map((elem) => ({ ...elem.data(), id: elem.id }));
      setProducts(eventData);
    }
    const unsubscribe = onSnapshot(collection(db, "products"), (snapshot) => {
      const updatedProducts = snapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setProducts(updatedProducts);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <div>
      <div className="table">
        <div className="table__wrapper">
          <button onClick={openModal} className="btn admin__btn">
            add product
          </button>
          <DataTable
            columns={columns}
            data={products}
            customStyles={customStyles}
          />
        </div>
      </div>
      <AddProductModal
        isModalOpen={isModalOpen}
        openModa={openModal}
        closeModal={closeModal}
      />
    </div>
  );
}

export default AdminProducts;
