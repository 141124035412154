import { addDoc, collection, doc, updateDoc } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import React, { useState } from "react";
import { db, storage } from "../firebase";

function AdminEvents({ resetActiveComponent }) {
  const [link, setLink] = useState("");
  const [dateTime, setDateTime] = useState("");
  const [image, setImage] = useState(null);
  const [imageURL, setImageURL] = useState("");
  const [loading, setLoading] = useState(false);

  function uploadImage(e) {
    const file = e.target.files[0];
    setImage(file);

    const reader = new FileReader();
    reader.onload = (event) => {
      setImageURL(event.target.result);
    };
    reader.readAsDataURL(file);
  }

  async function addEvent() {
    setLoading(true);
    const event = {
      link: link,
      date: new Date(dateTime),
    };
    const eventRef = await addDoc(collection(db, "events"), event);
    const eventId = eventRef.id;

    if (image) {
      const storageRef = ref(storage, `events/${eventId}`);
      const uploadTask = uploadBytesResumable(storageRef, image);

      await uploadTask;

      const imageUrl = await getDownloadURL(storageRef);

      await updateDoc(doc(db, "events", eventId), {
        img: imageUrl,
      });
    }
    setLoading(false);
    resetActiveComponent()
  }
  return (
    <div>
      <form className="product__form event__form">
        <div className="product__input">
          <label>Facebook link</label>
          <input
            type="url"
            value={link}
            onChange={(e) => setLink(e.target.value)}
          />
        </div>
        <div className="product__input">
          <label>Date & time</label>
          <input
            type="datetime-local"
            value={dateTime}
            onChange={(e) => setDateTime(e.target.value)}
          />
        </div>

        <label>Image:</label>
        <div className="event__input">
          <input type="file" onChange={uploadImage} />
          {imageURL && (
            <img
              src={imageURL}
              alt="Preview"
              style={{ width: "100px", height: "100px" }}
            />
          )}
        </div>
        {loading ? (
          <div className="spinner"></div>
        ) : (
          <button type="button" onClick={addEvent}>
            Add event
          </button>
        )}
      </form>
    </div>
  );
}

export default AdminEvents;
